<template>
  <v-card outlined color="#e8eaf6" class="pa-1 mt-1" @click="isList ? navigateOrder(model.id) : false">
    <v-card-text class="pa-1 pt-0 pb-0">
      <v-row>
        <v-col cols="2" class="mt-4 pa-0 pl-1">
          <span class="body-2">{{ model.number }}</span>
        </v-col>
        <v-col cols="8" class="pa-0 pl-5">
          <v-row class="mt-2">
            <v-col cols="12" class="pa-0">
              {{ model.customername }}
            </v-col>
            <v-col cols="6" class="pa-0">
              O: {{ model.orderdate | dateFormat }}
            </v-col>
            <v-col cols="6" class="pa-0">
              D: {{ model.deliverydate | dateFormat }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2" class="mt-4 pa-0 pr-2 text-right">
          <v-btn icon v-if="isList">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
          <h4 v-else>
            -{{ model.pickedqty | absoluteNumber }}
          </h4>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: {
    isList: {
      type: Boolean
    },
    model: {
      type: Object
    }
  },
  methods: {
    navigateOrder (id) {
      this.$router.push(`/customer_orders/${id}`)
    }
  }
}
</script>
